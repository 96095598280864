const BASE_URL = document
  .getElementsByTagName("BODY")[0]
  .getAttribute("base-uri");

let url = {
  ////////////////////////
  ///  START PUBLIC URL /
  //////////////////////

  publicMedia: {
    getShareLink: {
      url: BASE_URL + "api/view-reward/",
      method: "POST"
    },

    getMedia: {
      url: BASE_URL + "api/view-reward/",
      method: "POST"
    },

    getReward: {
      url: BASE_URL + "api/generate-reward",
      method: "POST"
    },

    history: {
      init: {
        url: BASE_URL + "api/initialize-view",
        method: "POST"
      }
    },

    parameters: {
      url: BASE_URL + "api/",
      method: "POST"
    }
  }

  //////////////////////
  ///  END PUBLIC URL /
  ////////////////////
};

export default url;
