import "babel-polyfill";
import Vue from "vue";
import "./plugins/vuetify";
import i18n from "./plugins/vuei18n";
import App from "./App.vue";
import store from "./store/index";
import router from "./router";
import { includes } from "lodash";

const title = document.title;

Vue.config.productionTip = false;
Vue.router = router;

Vue.filter("capitalize", function(value) {
  if (!value) return "";
  value = value.toString();
  return value.toUpperCase();
});

Vue.router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title + title;

  let PublicLayoutName = ["public"];
  if (includes(PublicLayoutName, to.name)) {
    //Public Layout should be apply
    store.commit("setLoadingPage", true);
    store.commit("setConfiguration", {
      drawer: null,
      color: "toolbar",
      title: i18n.t("navigation.title"),
      enabledMenu: true,
      background: "background: #F5F8F8"
    });
  }

  next();
});

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount("#app");
