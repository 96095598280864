export default {
  gb: {
    navigation: {
      title: "PUB SHARE"
    },
    global: {
      button: {
        back: "Back",
        share: "Share"
      },
      notification: {
        status: {
          CODE_200: "Registered Data",
          CODE_204: "Deleted Data",
          CODE_400: "Invalid data submitted",
          CODE_DEFAULT: "An error occurred while submitting the form"
        }
      },
      pages: {
        page_404: "Sorry, Page not found.",
        page_403: "Access denied. please contact the administrator.",
        page_500: "An error has occurred. We are working to solve this problem."
      }
    },
    reward: {
      public: {
        winner: "Please present the code in one of our branches. code : ",
        info: "Time remaining before obtaining a coupon",
        warning: "No coupon available for the moment ...",
        error: "Non-existent or deleted content"
      }
    }
  }
};
