export default {
  fr: {
    navigation: {
      title: "PUB SHARE"
    },
    global: {
      button: {
        back: "Retourner",
        share: "Partager"
      },
      notification: {
        status: {
          CODE_200: "Donnée Enregistrée",
          CODE_204: "Donnée Supprimée",
          CODE_400: "Données soumis invalide",
          CODE_DEFAULT:
            "Une erreur est survenue lors de la sousmission du formulaire"
        }
      },
      pages: {
        page_404: "Désolé, Page non trouvée",
        page_403: "Accès refusé",
        page_500:
          "Une erreur est survenue. Nous travaillons pour résoudre ce problème."
      }
    },
    reward: {
      public: {
        winner: "Veuillez présenter le code dans l'une de nos agences. code : ",
        info: "Temps restant avant l'obtention d'un coupon",
        warning: "Aucun coupon disponible pour le moment ...",
        error: "Contenu inexistant ou supprimé"
      }
    }
  }
};
