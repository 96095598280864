import Vue from "vue";
import VueI18n from "vue-i18n";
import fr from "../locales/i18n/fr";
import gb from "../locales/i18n/gb";

Vue.use(VueI18n);

let messages = { fr: fr.fr, gb: gb.gb };

const i18n = new VueI18n({
  fallbackLocale: "fr",
  messages
});

let userLang = navigator.language || navigator.userLanguage;

i18n.locale = userLang === "fr-FR" ? "fr" : "gb";

export default i18n;
