<template>
  <div>
    <v-content>
      <v-container fluid fill-height>
        <v-layout row wrap>
          <v-flex xs12 sm6 offset-sm3>
            <v-card elevation="0" :color="color">
              <v-alert
                v-if="
                  !isPreview && alert.info && client.content.timeToView != 0
                "
                :value="alert.info"
                type="info"
                :style="getConfiguration.background"
                outline
              >
                {{ $t("reward.public.info") }} {{ timeLeft }}
              </v-alert>
              <v-alert
                v-if="
                  !isPreview && alert.success && client.content.timeToView != 0
                "
                :value="alert.success"
                color="success"
                type="success"
                :style="getConfiguration.background"
                outline
              >
                {{ $t("reward.public.winner") }}
                <h3>{{ client.reward | capitalize }}</h3>
              </v-alert>
              <v-alert
                v-if="
                  !isPreview && alert.warning && client.content.timeToView != 0
                "
                :value="alert.warning"
                icon="warning"
                color="red"
                :style="getConfiguration.background"
                outline
              >
                {{ $t("reward.public.warning") }}
              </v-alert>
              <v-alert
                v-if="
                  !isPreview && alert.error && client.content.timeToView !== 0
                "
                :value="alert.error"
                icon="warning"
                color="red"
                :style="getConfiguration.background"
                outline
              >
                {{ $t("reward.public.error") }}
              </v-alert>
              <d-player
                ref="player"
                @play="play"
                @ended="ended"
                @pause="pause"
                @canplay="canplay"
                @canplaythrough="canplaythrough"
                @progress="progress"
                :options="getViewOptions"
                v-if="!getPictureType"
              >
              </d-player>
              <v-img v-else :src="viewText(client.content, 'mediaLink')">
              </v-img>
            </v-card>
            <div class="mt-4">
              <v-list two-line>
                <v-list-tile avatar>
                  <v-list-tile-avatar :size="85">
                    <v-img
                      :src="viewText(client.partner, 'logo_url')"
                      alt="avatar"
                    >
                    </v-img>
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title
                      >{{ client.partner.sigle | capitalize }}
                    </v-list-tile-title>
                    <v-list-tile-sub-title
                      >{{ viewText(client.content, "title") }}
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
                <p
                  class="mt-2 mx-4"
                  style="text-align: justify;text-justify: inter-word;"
                  v-html="viewText(client.content, 'description')"
                ></p>
              </v-list>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </div>
</template>

<script>
// HELPERS IMPORT
import URL from "../../helpers/Url";
import UTILITY from "../../helpers/Utility";
import { isEmpty, has } from "lodash";

// IMPORT MEDIA
import VueDPlayer from "vue-dplayer";
import "vue-dplayer/dist/vue-dplayer.css";

// IMPORT STORE
import { mapGetters, mapMutations, mapActions } from "vuex";

var intervalTimer;

export default {
  name: "PublicLayout",
  components: {
    "d-player": VueDPlayer
  },
  created: function() {
    this.isPreview = this.$route.query.v;
  },
  mounted: function() {
    let background = this.getConfiguration.background.replace(
      "background: ",
      ""
    );
    this.isPlaying = false;
    this.color = background + " lighten-5";
    if (!this.getPictureType) {
      this.player = this.$refs.player.dp;
    }
    if (this.$route.params.slug) {
      this.client.slug = this.$route.params.slug;
      let data = this.getResponse.data;
      this.client.config = data.config;
      if (this.$route.query.p) this.client.clientMsisdn = this.$route.query.p;
      this.getParameters(data);
      if (!this.isPreview) {
        if (this.getPictureType && isEmpty(this.client.history))
          this.initialiseView("init", false);
      }
    } else {
      this.$router.push({ name: "notFound" });
    }
  },
  computed: {
    ...mapGetters({
      getConfiguration: "configuration",
      getResponse: "response",
      getPictureType: "pictureType",
      getViewOptions: "viewOptions"
    })
  },
  data: () => ({
    alert: {
      info: true,
      success: false,
      error: false,
      warning: false
    },
    color: "grey lighten-5",
    timeLeft: "00:00",
    endTime: "0",
    player: null,
    client: {
      slug: null,
      clientMsisdn: null,
      content: {},
      partner: {},
      reward: null,
      history: null,
      config: null
    },
    timeout: 0,
    isPreview: false,
    isPlaying: false,
    isEnding: false
  }),
  methods: {
    ...mapMutations(["setConfiguration"]),

    ...mapActions({
      makeRequest: "makeRequest"
    }),
    setTime(seconds) {
      clearInterval(intervalTimer);
      this.timer(seconds);
    },
    timer(seconds) {
      const now = Date.now();
      const end = now + seconds * 1000;
      this.displayTimeLeft(seconds);
      this.displayEndTime(end);
      this.countdown(end);
    },
    countdown(end) {
      intervalTimer = setInterval(() => {
        const secondsLeft = Math.round((end - Date.now()) / 1000);

        if (secondsLeft === 0) {
          this.endTime = 0;
        }

        if (secondsLeft < 0) {
          clearInterval(intervalTimer);
          return;
        }
        this.displayTimeLeft(secondsLeft);
      }, 1000);
    },
    displayTimeLeft(secondsLeft) {
      const minutes = Math.floor((secondsLeft % 3600) / 60);
      const seconds = secondsLeft % 60;

      this.timeLeft = `${UTILITY.zeroPadded(minutes)}:${UTILITY.zeroPadded(
        seconds
      )}`;
    },
    displayEndTime: function(timestamp) {
      const end = new Date(timestamp);
      const hour = end.getHours();
      const minutes = end.getMinutes();

      this.endTime = `${UTILITY.hourConvert(hour)}:${UTILITY.zeroPadded(
        minutes
      )}`;
    },
    progress: function() {
      this.player.play();
    },

    canplay: function() {
      this.player.play();
    },

    canplaythrough: function() {
      this.player.play();
    },

    play: function() {
      if (!this.isPreview && !this.isPlaying) {
        if (isEmpty(this.client.history)) this.initialiseView("init", false);
        let timeout = this.timeout / 1000;
        this.setTime(timeout);
        setTimeout(this.getReward, this.timeout);
        this.isPlaying = true;
      }
    },

    pause: function() {},

    ended: function() {
      if (
        !this.isPreview &&
        !this.isEnding &&
        !isEmpty(this.client.history) &&
        has(this.client.history, "id")
      )
        this.getReward();
      this.isEnding = true;
    },

    // getShareLink: function() {
    //   let params = {
    //     url: URL.publicMedia.getShareLink.url,
    //     method: URL.publicMedia.getShareLink.method,
    //     data: this.formatSubmit("shareLink")
    //   };
    //
    //   this.makeRequest(params)
    //     .then(response => {})
    //     .catch(error => {});
    // },

    getParameters: function(data) {
      if (has(data, "content")) {
        this.client.content = data.content;
        this.client.partner = has(data, "partner_config")
          ? data.partner_config
          : {
              enterprise_name: "",
              logo_url: "",
              color: "toolbar",
              background: "background: #F5F8F8",
              sigle: "",
              website: ""
            };
        let background = "background: " + this.client.partner.background;
        this.displayTimeLeft(this.client.content.timeToView);
        this.timeout = parseInt(this.client.content.timeToView) * 1000;
        this.setConfiguration({
          drawer: null,
          color: this.client.partner.color,
          title: this.client.partner.enterprise_name,
          enabledMenu: false,
          background: background
        });
      } else {
        this.client.history = {};
        this.$router.push({ name: "NotFound" });
      }
    },

    getReward: function() {
      let data = this.formatSubmit("getReward");
      if (data.view_history !== 0 && this.client.clientMsisdn) {
        let params = {
          url: URL.publicMedia.getReward.url,
          method: URL.publicMedia.getReward.method,
          data: data
        };

        this.makeRequest(params)
          .then(response => {
            if (response.status === 200) {
              switch (response.data.code) {
                case 700:
                  this.setAlert("warning");
                  this.client.reward = null;
                  break;
                case 701:
                  this.client.reward = response.data.reward;
                  this.setAlert("success");
                  break;
                default:
                  this.setAlert("error");
                  this.client.reward = null;
                  break;
              }
            } else {
              this.setAlert("warning");
              this.client.reward = null;
            }
          })
          .catch(() => {
            this.setAlert("warning");
            this.client.reward = null;
          });
      }
    },

    initialiseView: function(action, untilTheEnd) {
      let url = URL.publicMedia.history.init.url;
      let method = URL.publicMedia.history.init.method;
      let params = {
        url: url,
        method: method,
        data: this.formatSubmit("init", untilTheEnd)
      };
      this.makeRequest(params)
        .then(response => {
          if (response.status === 200) {
            this.client.history = response.data;
            if (this.getPictureType) {
              let timeout = this.timeout / 1000;
              this.setTime(timeout);
              setTimeout(this.getReward, this.timeout);
            }
          }
        })
        .catch(() => {});
    },

    formatSubmit: function(action = "shareLink", params = null) {
      let format = {};
      let csrf = document
        .getElementsByTagName("BODY")[0]
        .getAttribute("app_csrf");

      switch (action) {
        case "shareLink":
          break;
        case "getReward":
          var historyId = has(this.client.history, "id")
            ? this.client.history.id
            : 0;
          format = { view_history: historyId, _token: csrf };
          break;
        case "init":
          format = {
            content: this.client.content.id,
            clientMsisdn: this.client.clientMsisdn,
            untilTheEnd: params,
            _token: csrf
          };
          break;
      }

      return format;
    },

    viewText: function(obj, key) {
      if (has(obj, key)) {
        if (obj[key] === null && key === "logo_url")
          return "https://emailing.lmtgroup.com/upload/default-logo.png";

        return obj[key];
      }
    },
    setAlert: function(type = "info") {
      switch (type) {
        case "success":
          this.alert.success = true;
          this.alert.info = false;
          this.alert.warning = false;
          this.alert.error = false;
          break;
        case "warning":
          this.alert.warning = true;
          this.alert.info = false;
          this.alert.success = false;
          this.alert.error = false;
          break;
        case "error":
          this.alert.error = true;
          this.alert.info = false;
          this.alert.success = false;
          this.alert.warning = false;
          break;
        default:
          this.alert.info = true;
          this.alert.success = false;
          this.alert.warning = false;
          this.alert.error = false;
          break;
      }
    }
  }
};
</script>

<style>
.dplayer {
  width: 650px;
  margin: auto auto;
}

@media (max-width: 2410px) {
  .dplayer {
    width: 100%;
  }

  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 16px;
  }
}

.v-list__tile__title {
  font-weight: 800;
}

.theme--light.v-icon {
  color: black;
}

p {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
