import Vue from "vue";
import Vuex from "vuex";
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

Vue.use(Vuex);

const state = {
  configuration: {
    drawer: null,
    color: "toolbar",
    title: "PUBSHARE",
    enabledMenu: false,
    background: "background: #F5F8F8"
  },
  loadingDatatable: false,
  notification: {
    timeout: 4000,
    text: "",
    color: "dark",
    status: false
  },
  language: {
    fr: "fr",
    en: "gb"
  },
  loadingPage: true,
  viewOptions: {
    autoplay: true,
    video: {
      url: ""
    },
    contextmenu: [],
    logo: ""
  },
  pictureType: false
};

let store = new Vuex.Store({
  state,
  getters,
  actions,
  mutations
});

export default store;
