const utity = {
  pictureType: function(url = "") {
    // let pattern = /^[a-zA-Z0-9]{1,}.(?<domaine>lmtgroup.com)\/(?<slug>[a-zA-Z0-9]{5})\?p\=(?<phone>[0-9]{12})$/
    let pattern = /\.(gif|jpg|jpeg|tiff|png|ico)$/;
    return pattern.test(url.toLowerCase());
  },

  zeroPadded: function(num) {
    // 4 --> 04
    return num < 10 ? `0${num}` : num;
  },

  hourConvert: function(hour) {
    // 15 --> 3
    return hour % 12 || 12;
  }
};

export default utity;
