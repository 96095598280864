const mutations = {
  setNotification(state, notification) {
    state.notification = notification;
  },

  setLanguage(state, language) {
    state.language = language;
  },

  setConfiguration(state, config) {
    state.configuration = config;
  },

  setLoadingPage(state, loadingPage) {
    state.loadingPage = loadingPage;
  },

  setResponse(state, response) {
    state.response = response;
  },

  setViewOptions(state, viewOptions) {
    state.viewOptions = viewOptions;
  },

  setPictureType(state, pictureType) {
    state.pictureType = pictureType;
  }
};

export default mutations;
