const getters = {
  notification: state => state.notification,
  language: state => state.language,
  configuration: state => state.configuration,
  loadingPage: state => state.loadingPage,
  response: state => state.response,
  pictureType: state => state.pictureType,
  viewOptions: state => state.viewOptions
};

export default getters;
