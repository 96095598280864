import http from "./http";

let qs = require("qs");

const prefix = "pushare_";

const actions = {
  list: (context, params) => {
    return new Promise((resolve, reject) => {
      http
        .get(params.url, {
          params: params.params,
          paramsSerializer: function(params) {
            return qs.stringify(params, { encode: false });
          }
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  makeRequest: (context, params) => {
    return new Promise((resolve, reject) => {
      http({
        url: params.url,
        method: params.method,
        data: params.data
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  saveInStorage: (context, params) => {
    let key = prefix + params.key;
    localStorage.setItem(key, params.data);
  },

  deleteInStorage: (context, key) => {
    key = prefix + key;
    localStorage.removeItem(key);
  },

  getInStorage: (context, key) => {
    key = prefix + key;
    let local = localStorage.getItem(key);
    return new Promise((resolve, reject) => {
      if (local) resolve(local);
      else reject(local);
    });
  }
};

export default actions;
