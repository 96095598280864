var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-content',[_c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":"","offset-sm3":""}},[_c('v-card',{attrs:{"elevation":"0","color":_vm.color}},[(
                !_vm.isPreview && _vm.alert.info && _vm.client.content.timeToView != 0
              )?_c('v-alert',{style:(_vm.getConfiguration.background),attrs:{"value":_vm.alert.info,"type":"info","outline":""}},[_vm._v("\n              "+_vm._s(_vm.$t("reward.public.info"))+" "+_vm._s(_vm.timeLeft)+"\n            ")]):_vm._e(),(
                !_vm.isPreview && _vm.alert.success && _vm.client.content.timeToView != 0
              )?_c('v-alert',{style:(_vm.getConfiguration.background),attrs:{"value":_vm.alert.success,"color":"success","type":"success","outline":""}},[_vm._v("\n              "+_vm._s(_vm.$t("reward.public.winner"))+"\n              "),_c('h3',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.client.reward)))])]):_vm._e(),(
                !_vm.isPreview && _vm.alert.warning && _vm.client.content.timeToView != 0
              )?_c('v-alert',{style:(_vm.getConfiguration.background),attrs:{"value":_vm.alert.warning,"icon":"warning","color":"red","outline":""}},[_vm._v("\n              "+_vm._s(_vm.$t("reward.public.warning"))+"\n            ")]):_vm._e(),(
                !_vm.isPreview && _vm.alert.error && _vm.client.content.timeToView !== 0
              )?_c('v-alert',{style:(_vm.getConfiguration.background),attrs:{"value":_vm.alert.error,"icon":"warning","color":"red","outline":""}},[_vm._v("\n              "+_vm._s(_vm.$t("reward.public.error"))+"\n            ")]):_vm._e(),(!_vm.getPictureType)?_c('d-player',{ref:"player",attrs:{"options":_vm.getViewOptions},on:{"play":_vm.play,"ended":_vm.ended,"pause":_vm.pause,"canplay":_vm.canplay,"canplaythrough":_vm.canplaythrough,"progress":_vm.progress}}):_c('v-img',{attrs:{"src":_vm.viewText(_vm.client.content, 'mediaLink')}})],1),_c('div',{staticClass:"mt-4"},[_c('v-list',{attrs:{"two-line":""}},[_c('v-list-tile',{attrs:{"avatar":""}},[_c('v-list-tile-avatar',{attrs:{"size":85}},[_c('v-img',{attrs:{"src":_vm.viewText(_vm.client.partner, 'logo_url'),"alt":"avatar"}})],1),_c('v-list-tile-content',[_c('v-list-tile-title',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.client.partner.sigle))+"\n                  ")]),_c('v-list-tile-sub-title',[_vm._v(_vm._s(_vm.viewText(_vm.client.content, "title"))+"\n                  ")])],1)],1),_c('p',{staticClass:"mt-2 mx-4",staticStyle:{"text-align":"justify","text-justify":"inter-word"},domProps:{"innerHTML":_vm._s(_vm.viewText(_vm.client.content, 'description'))}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }