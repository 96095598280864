<template>
  <v-app>
    <div style="text-align:center; padding-top:100px;" v-if="getLoadingPage">
      <v-progress-circular
        :size="100"
        color="red"
        indeterminate
      ></v-progress-circular>
    </div>
    <app-navigation v-else></app-navigation>
  </v-app>
</template>

<script>
import Navigation from "./base/Navigation.vue";

import URL from "../helpers/Url";
import UTILITY from "../helpers/Utility";
import { has } from "lodash";

// IMPORT STORE
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "Layout",
  components: {
    "app-navigation": Navigation
  },
  created: function() {
    this.isPreview = this.$route.query.v;
  },
  mounted: function() {
    if (this.$route.params.slug) {
      if (this.$route.query.p) this.clientMsisdn = this.$route.query.p;
      this.getParameters(this.$route.params.slug);
    }
  },
  data: () => ({
    clientMsisdn: null,
    isPreview: false
  }),
  computed: {
    ...mapGetters({
      getLoadingPage: "loadingPage",
      getLanguage: "language"
    })
  },
  methods: {
    ...mapMutations([
      "setLoadingPage",
      "setResponse",
      "setPictureType",
      "setViewOptions"
    ]),

    ...mapActions({
      makeRequest: "makeRequest"
    }),

    getParameters: function(slug = null) {
      let csrf = document
        .getElementsByTagName("BODY")[0]
        .getAttribute("app_csrf");
      let params = {
        url: URL.publicMedia.parameters.url,
        method: URL.publicMedia.parameters.method,
        data: { slug: slug, _token: csrf }
      };

      this.makeRequest(params)
        .then(response => {
          let data = response.data;
          if (has(data, "content")) {
            let language = this.getLanguage;
            this.$i18n.locale = language[data.content.language];
            // let logoUrl = has(data, "partner_config")
            //   ? data.partner_config.logo_url
            //   : "";
            this.setViewOptions({
              autoplay: true,
              video: { url: data.content.mediaLink },
              contextmenu: [
                {
                  text: data.partner_config.sigle,
                  link: data.partner_config.website
                }
              ],
              logo: ""
            });
            this.setPictureType(UTILITY.pictureType(data.content.mediaLink));
          }
          this.setResponse(response);
          if (this.canRedirect(data)) {
            if (!this.isPreview) this.initialiseView(data);
            else window.location.href = data.spot.redirect_url;
          } else {
            this.setLoadingPage(false);
          }
        })
        .catch(error => {
          if (has(error, "response")) {
            switch (error.response.status) {
              case 400:
                this.$router.push({ name: "NotFound" });
                break;
              case 401:
                this.$router.push({ name: "Forbidden" });
                break;
              default:
                this.$router.push({ name: "InternalServerError" });
                break;
            }
          }
        });
    },
    canRedirect: function(data) {
      let redirect = false;
      if (has(data, "spot")) {
        redirect = data.spot.redirect;
      }
      return redirect;
    },
    initialiseView: function(data, finished = false) {
      let self = this;
      let url = URL.publicMedia.history.init.url;
      let method = URL.publicMedia.history.init.method;
      let params = {
        url: url,
        method: method,
        data: this.formatData(data, finished)
      };
      this.makeRequest(params)
        .then(response => {
          if (
            has(response.data, "until_the_end") &&
            response.data.until_the_end === false
          ) {
            self.getReward(response.data, data.spot.redirect_url);
          } else {
            window.location.href = data.spot.redirect_url;
          }
        })
        .catch(() => {});
    },
    getReward: function(data, redirectUrl) {
      let params = {
        url: URL.publicMedia.getReward.url,
        method: URL.publicMedia.getReward.method,
        data: this.formatData(data, true)
      };

      this.makeRequest(params)
        .then(() => {
          window.location.href = redirectUrl;
        })
        .catch(() => {});
    },
    formatData: function(data, finished) {
      let csrf = document
        .getElementsByTagName("BODY")[0]
        .getAttribute("app_csrf");
      if (finished) return { view_history: data.id, _token: csrf };
      return {
        content: data.content.id,
        clientMsisdn: this.clientMsisdn,
        untilTheEnd: false,
        _token: csrf
      };
    }
  }
};
</script>

<style>
.headline {
  font-size: 17px !important;
  font-weight: 700;
  line-height: 32px !important;
  letter-spacing: normal !important;
  font-family: "Nunito", sans-serif !important;
}

.material-icons {
  font-size: 21px !important;
}
</style>
