import Vue from "vue";
import Router from "vue-router";

//BASE LAYOUT
import Layout from "../components/Layout";

//EXCEPTION
import NotFound from "../components/error/404";
import Forbidden from "../components/error/403";
import InternalServerError from "../components/error/500";

//PUBLIC LAYOUT
import PublicLayout from "../components/menu/PublicLayout";

Vue.use(Router);

export default new Router({
  // mode: "history",
  routes: [
    {
      path: "/403",
      name: "Forbidden",
      component: Forbidden,
      meta: {
        title: "403 Forbidden - "
      }
    },
    {
      path: "/500",
      name: "InternalServerError",
      component: InternalServerError,
      meta: {
        title: "500 Internal Server Error - "
      }
    },
    {
      path: "",
      component: Layout,
      children: [
        {
          path: "/",
          redirect: {
            name: "NotFound"
          }
        },
        {
          path: "/:slug",
          component: PublicLayout,
          name: "public",
          meta: {
            title: "Pub - "
          }
        }
      ]
    },
    {
      path: "/404",
      component: NotFound,
      name: "NotFound",
      meta: {
        title: "404 Not Found - "
      }
    },
    {
      path: "*",
      redirect: { name: "NotFound" }
    }
  ]
});
