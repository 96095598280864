import Vue from "vue";
import Vuetify from "vuetify/lib";
import "../assets/stylus/app.styl";

Vue.use(Vuetify, {
  iconfont: "md",
  theme: {
    toolbar: "#3F3169",
    primary: "#1D46D2",
    secondary: "#C95303",
    error: "#D50000",
    info: "#2196F3",
    success: "#00BFA5",
    warning: "#FFC107"
  }
});
