<template>
  <v-app id="500">
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <div class="mr-3 hidden-sm-and-down">
          <img src="../../assets/500.svg" alt="500" />
        </div>
        <div class="text-md-center">
          <h1>500</h1>
          <h2 class="my-3 headline">{{ $t("global.pages.page_500") }}</h2>
        </div>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "InternalServerError"
};
</script>

<style scoped lang="css">
h1 {
    font-size: 150px;
    line-height: 150px;
    font-weight: 700;
    color: #252932;
    text-shadow: rgba(61, 61, 61, 0.3) 1px 1px, rgba(61, 61, 61, 0.2) 2px 2px, rgba(61, 61, 61, 0.3) 3px 3px;
}
</style>
